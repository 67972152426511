import React, { useState } from "react";
import "../../assets/css/sobre.css";

const Contato = (props) => {
  const randomIntFromInterval = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  };

  const [number1, setNumber1] = useState(randomIntFromInterval(1, 9));
  const [number2, setNumber2] = useState(randomIntFromInterval(1, 9));
  const sendEmailHandler = () => {
    console.log("Opa");
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Access-Control-Allow-Origin", "*");

    const captchaValue = parseInt(document.querySelector("#captcha").value);
    if (captchaValue !== number1 + number2) {
      return;
    }

    fetch("https://t97ro6uvg7.execute-api.us-east-2.amazonaws.com/staging", {
      method: "post",
      headers: myHeaders,
      mode: "no-cors",
      body: JSON.stringify({
        from: "contato@lifstds.com",
        subject: "Contato - Site Dr. Bevilaqua",
        toName: "Contato - Site Dr. Bevilaqua",
        toEmail: "luizandersonband@gmail.com",
        content:
          "Esse email foi enviado da página de Contato do site Dr. Bevilaqua \n\n" +
          "nome: " +
          document.querySelector("#name").value +
          "\n" +
          "email: " +
          document.querySelector("#email").value +
          "\n" +
          "mensagem: " +
          document.querySelector("#message").value +
          "\n",
      }),
    });
    if (document.querySelector("#send-to-me").checked) {
      fetch("https://t97ro6uvg7.execute-api.us-east-2.amazonaws.com/staging", {
        method: "post",
        headers: myHeaders,
        mode: "no-cors",
        body: JSON.stringify({
          from: "contato@lifstds.com",
          subject: "Contato - Site Dr. Bevilaqua",
          toName: "Contato - Site Dr. Bevilaqua",
          toEmail: document.querySelector("#email").value,
          content:
            "Esse email foi enviado da página de Contato do site Dr. Bevilaqua \n\n" +
            "nome: " +
            document.querySelector("#name").value +
            "\n" +
            "email: " +
            document.querySelector("#email").value +
            "\n" +
            "mensagem: " +
            document.querySelector("#message").value +
            "\n",
        }),
      });
    }

    document.querySelector("#name").value = "";
    document.querySelector("#email").value = "";
    document.querySelector("#message").value = "";
    document.querySelector("#send-to-me").checked = false;
    document.querySelector("#captcha").value = "";
    document.querySelector("#success").classList.remove("d-none");
    setNumber1(randomIntFromInterval(1, 9));
    setNumber2(randomIntFromInterval(1, 9));
    setTimeout(
      () => document.querySelector("#success").classList.add("d-none"),
      3000
    );
  };
  return (
    <div
      className="row  pt-5 pb-5 pl-3 pr-3 pl-lg-0 pr-lg-0 mode light-mode"
      id="contatosection"
    >
      <div className="col-lg-11 col-12 mx-auto">
        <div className="row">
          <div className="col-lg-5 mx-auto col-12 p-lg-0">
            <h1 className="texto-lato-heavy d-lg-block d-none texto-primario">
              Contato
            </h1>
            <h1 className="texto-lato-heavy d-lg-none d-block texto-primario">
              Contato
            </h1>
          </div>
          <div className="col-lg-5 mx-auto col-12"></div>
        </div>
        <div className="row">
          <div className="col-lg-5 col-12 p-lg-0 mx-auto">
            <p className="texto-lato-regular texto-cinza texto-body mt-4">
              Para que a mensagem seja entregue e respondida o mais brevemente,
              preencha o formulário ao lado, iniciando pelo objetivo de seu
              contato.
            </p>
            <p className="texto-lato-regular texto-cinza texto-body">
              O objetivo será avaliado poderá entrar já como prioridade pela
              equipe do Dr. Luiz Anderson Bevilaqua.{" "}
            </p>
            <p className="texto-lato-regular texto-cinza texto-body">
              Agradecemos o seu contato.<br></br>
              Atenciosamente.
            </p>
          </div>

          <div className="col-lg-5 col-12 p-lg-0  mx-auto">
            <div className="row">
              <div className="col">
                <div className="row">
                  <div className="col">
                    <p className="texto-lato-regular texto-cinza">Nome</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <input
                      type="text"
                      className="col pt-3 pb-3  border-0 radius"
                      style={{ backgroundColor: "#f3f3f3" }}
                      id="name"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col">
                <div className="row">
                  <div className="col">
                    <p className="texto-lato-regular texto-cinza">Mensagem</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <textarea
                      className="col border-0 radius pt-3 pb-3"
                      rows="4"
                      cols="50"
                      style={{ backgroundColor: "#f3f3f3" }}
                      id="message"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col">
                <div className="row">
                  <div className="col">
                    <p className="texto-lato-regular texto-cinza">Email</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="row">
                      <div className="col-12">
                        <input
                          type="text"
                          className="col pt-3 pb-3  border-0 radius"
                          style={{ backgroundColor: "#f3f3f3" }}
                          id="email"
                        />
                      </div>
                      <div className="col-lg-5 d-flex align-items-center">
                        <input
                          type="checkbox"
                          className=" border-0 radius d-inline"
                          style={{ backgroundColor: "#f3f3f3" }}
                          id="send-to-me"
                        />
                        <p className="texto-lato-regular texto-body ml-2 d-inline mt-3 mt-lg-3">
                          Enviar-me uma cópia
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col">
                <div className="row">
                  <div className="col-12 col-sm-4 d-flex align-items-center">
                    <p className="texto-lato-regular texto-cinza p-0 m-0">
                      {number1} + {number2} ?
                    </p>
                    <input
                      type="number"
                      className="col pt-3 pb-3 ml-2  border-0 radius"
                      style={{ backgroundColor: "#f3f3f3" }}
                      id="captcha"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col">
                <div className="row">
                  <div className="col">
                    <button
                      className="col border-0 bg-primario-escuro texto-lato-heavy radius text-white pt-3 pb-3"
                      onClick={sendEmailHandler}
                    >
                      Enviar
                    </button>
                    <p id="success" className="d-none">
                      Mensagem enviada com sucesso.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contato;
