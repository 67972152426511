import React from "react"
import "../../assets/css/sobre.css"
import Slider from "../Slider/Slider";
import {Carousel} from "react-bootstrap"


export default props =>

    <div className="row pt-5 pb-5 instagram instagram-light d-none" id="instagramsection">
        <div className="col-lg-10 col-12 mx-auto p-lg-0">
            <div className="row">
                <div className="col p-lg-0">
                    <h1 className="texto-lato-heavy text-white">Acompanhe nosso <a><img src="./Instagram.svg" /></a></h1>
                    <p className="texto-lato-regular text-white">Veja as últimas publicações e não esqueça de <a href="https://www.instagram.com/bevilaqua.neurocirurgia/?hl=en" className="texto-lato-regular" style={{ color: "#94B7FF" ,textDecoration:"underline" }}>seguir</a> e compartilhar com amigos e familiares.</p>
                </div>
            </div>
            <div className="row d-lg-flex justify-content-between mt-3 d-none">
                <div className="col-2 bg-white" style={{ height: "200px" }}>

                </div>
                <div className="col-2 bg-white" style={{ height: "200px" }}>

                </div>
                <div className="col-2 bg-white" style={{ height: "200px" }}>

                </div>
                <div className="col-2 bg-white" style={{ height: "200px" }}>

                </div>
                <div className="col-2 bg-white" style={{ height: "200px" }}>

                </div>
            </div>

            <Carousel className="d-lg-none d-block" indicators={false}>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src="./foto.jpg"
                        alt="First slide"
                    />
                    
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src="./foto.jpg"
                        alt="Second slide"
                    />

                    
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src="./foto.jpg"
                        alt="Third slide"
                    />

                   
                </Carousel.Item>
            </Carousel>
        </div>

    </div>
