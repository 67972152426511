import React from "react"
import "../../assets/css/sobre.css"

export default props =>

    <div className="row pt-3">
        <div className="col-lg-11 col-12 mx-auto ">
            <div className="row">
                <div className="col-12 col-lg-6">
                    <p className="text-center text-lg-left texto-lato-regular text-white">2021 - Todos os Direito Reservados - Dr Luiz Anderson Bevilaqua </p>
                </div> 
                <div className="col-12 col-lg-6">
                    <ul className="list-inline d-flex justify-content-center justify-content-lg-end">
                        <li className="d-inline mr-lg-3"><a href="https://www.instagram.com/bevilaqua.neurocirurgia/"><img src="./Instagram.svg"/></a></li>
                        <li className="d-inline mr-lg-3 ml-3"><a href="https://www.facebook.com/Luiz-Anderson-Bevilaqua-114594476616477"><img src="./Facebook.svg"/></a></li>
                        <li className="d-inline mr-lg-3 ml-3"><a href="https://www.linkedin.com/in/luizbevilaqua/"><img src="./Linkedin.svg"/></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>