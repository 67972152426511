import React, { useState } from "react";
import { Navbar } from "react-bootstrap";
import "../../assets/css/navbar.css"
import Lua from "../../assets/icons/Lua.svg"

export default function NavbarComponent() {

    const [isDark, setIsDark] = useState(false)

    // metodo abrir menu mobile
    const showMenu = () => {

        const menu = document.querySelector(".menumobile")
        const navbar = document.querySelector(".navbar-mobile")

        if (menu.classList.contains("d-none")) {

            navbar.classList.add("slideIn")
            menu.classList.remove("d-none")
            navbar.classList.remove("slideOut")



        } else {
            navbar.classList.remove("slideIn")
            navbar.classList.add("slideOut")

            setTimeout(() => {
                menu.classList.add("d-none")
            }, 500)


        }
    }

    const scrollToSection = (e) => {
        e.preventDefault()
        const section = document.querySelector("#" + e.target.id + "section")
        window.scrollTo({
            behavior: "smooth",
            top: section.offsetTop
        })
    }

    const toggleMode = () => {
        isDark ? setIsDark(false) : setIsDark(true)
        if (!isDark) {
            const allSections = document.querySelectorAll(".mode")
            const arraySections = Array.from(allSections)
            const tratamentos = Array.from(document.querySelectorAll(".tratamentos"))
            tratamentos.forEach(item => {
                item.classList.remove("bg-white")
                item.classList.add("bg-section-tratamentos-dark")
            })

            const btnTratamentos = Array.from(document.querySelectorAll(".btn-tratamentos"))
            btnTratamentos.forEach(item => {
                item.classList.remove("acordion-button")
                item.classList.add("acordion-button-dark")
            })

            const svgs = Array.from(document.querySelectorAll("svg path"))
            console.log(svgs)

            svgs.forEach((item, index) => {

                item.setAttribute("fill", "#C3D7FF")

            })


            const sobre = document.querySelector(".sobre")
            sobre.classList.remove("sobre")
            sobre.classList.add("sobre-dark")
            const lista = document.querySelector(".lista")
            lista.classList.remove("lista-style")
            lista.classList.add("lista-dark")

            const instagram = document.querySelector(".instagram");
            instagram.classList.remove("instagram-light");
            instagram.classList.add("instagram-dark")
            const footer = document.querySelector(".footer");
            footer.classList.remove("footer-light")
            footer.classList.add("bg-primario")
            arraySections.forEach(item => {
                item.classList.add("dark-mode")
            })
        } else {
            const allSections = document.querySelectorAll(".mode")
            const arraySections = Array.from(allSections)
            const tratamentos = Array.from(document.querySelectorAll(".tratamentos"))
            tratamentos.forEach(item => {
                item.classList.remove("bg-section-tratamentos-dark")
                item.classList.add("bg-white")
            })
            const sobre = document.querySelector(".sobre-dark")
            sobre.classList.add("sobre")

            const btnTratamentos = Array.from(document.querySelectorAll(".btn-tratamentos"))
            btnTratamentos.forEach(item => {
                item.classList.remove("acordion-button-dark")
                item.classList.add("acordion-button")
            })

            const svgs = Array.from(document.querySelectorAll("svg path"))
            svgs.forEach((item, index) => {
                if (!item.classList.contains("check")) {
                    item.setAttribute("fill", "#1E3B5F")
                } else {
                    item.setAttribute("stroke", "#1E3B5F")
                }
            })

            const instagram = document.querySelector(".instagram");
            instagram.classList.add("instagram-light");
            instagram.classList.remove("instagram-dark")

            const lista = document.querySelector(".lista")
            lista.classList.remove("lista-dark")
            lista.classList.add("lista-style")
            const footer = document.querySelector(".footer");
            footer.classList.add("footer-light")
            footer.classList.remove("bg-primario")
            arraySections.forEach(item => {
                item.classList.remove("dark-mode")
                item.classList.add("light-mode")
            })
        }
    }

    return (
        <div>
            <nav className="navbar navbar-expand-lg navbar-light cima bg-primario-escuro m-0">
                <div className="d-flex align-itens-center justify-content-between d-lg-none w-100 pl-2 pr-2">
                    {isDark && (
                        <button className="border-0 bg-transparent" onClick={() => toggleMode()}><img src="./Sol.svg" /></button>
                    )}
                    {!isDark && (
                        <button className="border-0 bg-transparent" onClick={() => toggleMode()}><img src="./Lua.svg" /></button>
                    )}
                    <a className="nav-link text-white texto-regular"><img src="./Logo.svg" /></a>
                    <button onClick={() => showMenu()} class="navbar-toggler border-0 float-right p-0" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                        <img src="./Menu.svg" />
                    </button>
                </div>
                <div className=" d-none d-lg-block col-10 mx-auto p-lg-0 pr-lg-0 ">
                    <div className="navbar-nav col-12 d-flex justify-content-between align-items-center">
                        <div className="d-flex pb-2 pt-2">
                            <a><img src="./Logo.svg" /></a>
                        </div>

                        <div className="d-flex">
                            <a className="nav-link text-white ml-lg-0 mt-xl-0 mt-3 mr-lg-2 ml-lg-4 texto-regular" href="#" id="sobredois" onClick={(e) => scrollToSection(e)}>Dr. Luiz Anderson Bevilaqua</a>
                            <a className="nav-link text-white ml-lg-0 mt-xl-0 mt-3 mr-lg-2 ml-lg-4 texto-regular" href="#" id="tratamentos" onClick={(e) => scrollToSection(e)}>Tratamentos</a>
                            <a className="nav-link text-white ml-lg-0 mt-xl-0 mt-3 mr-lg-3 ml-lg-4 texto-regular" href="#" id="contato" onClick={(e) => scrollToSection(e)}>Contato</a>
                            {/*<a className="nav-link text-white ml-lg-0 mt-xl-0 mt-3 mr-lg-4 ml-lg-4 texto-regular" href="#" id="instagram" onClick={(e) => scrollToSection(e)}>Instagram</a>*/}
                            <div className="d-flex d-inline">
                                <button className="bg-transparent border-0 " onClick={() => toggleMode()}>{isDark ? <img src="./Sol.svg" /> : <img src="./Lua.svg" />}</button>
                            </div>
                        </div>


                    </div>
                </div>
            </nav>
            <div class="menumobile d-none d-lg-none">
                <div class="navbar-nav pl-4 embaixo w-100 pb-3 navbar-mobile position-absolute bg-primario-escuro">
                    <a className="nav-link text-white ml-lg-3 texto-regular pt-3" href="#" id="sobredois" onClick={(e) => scrollToSection(e)}>Dr. Luiz Anderson Bevilaqua</a>
                    <a className="nav-link text-white ml-lg-3 mt-3 texto-regular" href="#" id="tratamentos" onClick={(e) => scrollToSection(e)}>Tratamentos</a>
                    <a className="nav-link text-white ml-lg-3 mt-3 texto-regular" href="#" id="contato" onClick={(e) => scrollToSection(e)}>Contato</a>
                    {/*<a className="nav-link text-white ml-lg-3 mt-3 texto-regular" href="#" id="instagram" onClick={(e) => scrollToSection(e)}>Instagram</a>*/}
                </div>
            </div>
        </div>
    )


}





