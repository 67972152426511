import React from "react"
import "../../assets/css/sobre.css"
import Check from "../../assets/icons/Check.svg"

export default function Sobre() {

    const goToSection = (e) => {
        const section = document.querySelector("#" + e.target.id + "section")
        console.log(section)
        window.scrollTo({
            behavior: "smooth",
            top: section.offsetTop
        })
    }

    return (
        <div className="" id="sobresection">
            <div className="row mode">
                <div className="col-lg-6 col-12 p-0 bg-primario d-flex justify-content-center">

                    <img src="./ilustracao.png" className="img-fluid" />

                </div>
                <div className="col bg-sobre d-flex flex-column pt-lg-5 ">
                    <div className="row d-none d-lg-block d-md-none">
                        <div className="col mt-lg-5 mt-0 p-0">
                            <ul className="mt-lg-5 mt-0 pl-0 mb-1">
                                <li className="d-inline texto-lato-heavy "><button className="border-0 bg-transparent text-white lista-btn" id="sobredois" onClick={(e) => goToSection(e)}>Médico</button></li>
                                <li className="d-inline texto-lato-heavy "><button className="border-0 bg-transparent text-white lista-btn ml-lg-4 ml-0" id="tratamentos" onClick={(e) => goToSection(e)}>Agendar Consulta</button></li>
                                <li className="d-inline texto-lato-heavy "><button className="border-0 bg-transparent text-white lista-btn ml-lg-4 ml-0" id="tratamentos" onClick={(e) => goToSection(e)}>Tratamentos</button></li>
                            </ul>

                        </div>
                    </div>
                    <div className="row d-none d-lg-block">
                        <div className="bg-white rounded" style={{ width: "75px", height: "5px" }}>

                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-lg-10 col-xl-5 col-12 pl-3 pr-3 pl-lg-0 pr-lg-0 sobre" >
                            <h1 className="texto-lato-heavy d-lg-block d-none titulo-header">Dr. Luiz Anderson Bevilaqua</h1>
                            <h1 className="texto-lato-heavy d-lg-none d-block">Dr. Luiz Anderson Bevilaqua</h1>
                            <p className="texto-lato-regular">Neurocirurgião focado em diagnóstico, tratamento e reabilitação de doenças neurológicas.</p>
                        </div>
                    </div>
                    <div className="row d-block d-lg-none d-md-block pt-2 pt-lg-0 lista lista-style">
                        <div className="col-12 mt-lg-5 d-flex justify-content-between " >
                            <ul className="mt-lg-5 mt-0 pl-0 mb-1 d-flex ">
                                <li className="d-inline texto-lato-heavy "><button className="border-0 bg-transparent lista-btn" id="sobredois" style={{fontSize:"0.99em"}} onClick={(e) => goToSection(e)}>Médico</button></li>
                                <li className="d-inline texto-lato-heavy ml-2"><button className="border-0 bg-transparent lista-btn ml-lg-4 ml-0" style={{fontSize:"0.99em"}} id="tratamentos" onClick={(e) => goToSection(e)}>Agendar Consulta</button></li>
                                <li className="d-inline texto-lato-heavy ml-2"><button className="border-0 bg-transparent lista-btn ml-lg-4 ml-0" id="tratamentos" style={{fontSize:"0.99em"}} id="tratamentos" onClick={(e) => goToSection(e)}>Tratamentos</button></li>
                            </ul>
                        </div>
                    </div>
                    <div className="row mt-2 d-block d-lg-nonme">
                        <div className="col p-0">
                            <div className="ml-3 bg-primario rounded" style={{ width: "60px", height: "5px" }}>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row " id="sobredoissection">
                <div className="col-12 d-flex flex-column pt-lg-5 pb-5 mode light-mode">
                    <div className="row mt-5">
                        <div className="col-lg-11 col-12 mx-auto pl-3 pr-3 pl-lg-0 pr-lg-0">
                            <div className="row">
                                <div className="col-12 col-lg-5 mx-auto">
                                    <h1 className="texto-lato-heavy d-lg-block d-none texto-primario">Neurocirurgião</h1>
                                    <h1 className="texto-lato-heavy d-lg-none d-block texto-primario">Neurocirurgião</h1>
                                    <h5 className="texto-semibold mt-3">Formação</h5>
                                    <div className="row mt-3">
                                        <div className=" col-12 d-block d-lg-none">
                                            <div className="row">
                                                <div className="col-1">
                                                    <svg width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C9.8059 2 11.4255 2.79786 12.5255 4.06031L8 8.5858L6.70711 7.29289C6.31658 6.90237 5.68342 6.90237 5.29289 7.29289C4.90237 7.68342 4.90237 8.3166 5.29289 8.7071L7.29289 10.7071C7.68342 11.0976 8.3166 11.0976 8.7071 10.7071L13.5924 5.82182C13.8556 6.49704 14 7.23165 14 8Z" fill="#1E3B5F" />
                                                    </svg>
                                                </div>
                                                <div className="col p-0 ml-2">
                                                    <p className="texto-lato-regular texto-body ml-lg-" style={{ fontSize: "1.1em" }}>Médico Neurocirurgião pelo Instituto Dr. José Frota (IJF)</p>
                                                </div>
                                            </div>

                                        </div>
                                        <div className=" col d-lg-block d-none">

                                            <p className="texto-lato-regular texto-body d-inline " style={{ fontSize: "1.1em" }}><svg className="mr-2" width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C9.8059 2 11.4255 2.79786 12.5255 4.06031L8 8.5858L6.70711 7.29289C6.31658 6.90237 5.68342 6.90237 5.29289 7.29289C4.90237 7.68342 4.90237 8.3166 5.29289 8.7071L7.29289 10.7071C7.68342 11.0976 8.3166 11.0976 8.7071 10.7071L13.5924 5.82182C13.8556 6.49704 14 7.23165 14 8Z" fill="#1E3B5F" />
                                            </svg> Neurocirurgião pelo Instituto Dr. José Frota (IJF)</p>
                                        </div>
                                    </div>
                                    <div className="row mt-lg-3">
                                        <div className=" col-12 d-block d-lg-none">
                                            <div className="row">
                                                <div className="col-1">
                                                    <svg width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C9.8059 2 11.4255 2.79786 12.5255 4.06031L8 8.5858L6.70711 7.29289C6.31658 6.90237 5.68342 6.90237 5.29289 7.29289C4.90237 7.68342 4.90237 8.3166 5.29289 8.7071L7.29289 10.7071C7.68342 11.0976 8.3166 11.0976 8.7071 10.7071L13.5924 5.82182C13.8556 6.49704 14 7.23165 14 8Z" fill="#1E3B5F" />
                                                    </svg>
                                                </div>
                                                <div className="col p-0 ml-2">
                                                    <p className="texto-lato-regular texto-body " >Mestre em Tecnologia Minimamente Invasiva e Simulação na Área de Saúde - UniChristus</p>
                                                </div>
                                            </div>

                                        </div>
                                        <div className=" col d-lg-block d-none">

                                            <p className="texto-lato-regular d-inline texto-body " ><svg width="24" className="mr-2" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C9.8059 2 11.4255 2.79786 12.5255 4.06031L8 8.5858L6.70711 7.29289C6.31658 6.90237 5.68342 6.90237 5.29289 7.29289C4.90237 7.68342 4.90237 8.3166 5.29289 8.7071L7.29289 10.7071C7.68342 11.0976 8.3166 11.0976 8.7071 10.7071L13.5924 5.82182C13.8556 6.49704 14 7.23165 14 8Z" fill="#1E3B5F" />
                                            </svg>Mestre em Tecnologia Minimamente Invasiva e Simulação na Área de Saúde - UniChristus</p>
                                        </div>
                                    </div>
                                    <div className="row mt-lg-3">
                                        <div className=" col-12 d-block d-lg-none">
                                            <div className="row">
                                                <div className="col-1">
                                                    <svg width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C9.8059 2 11.4255 2.79786 12.5255 4.06031L8 8.5858L6.70711 7.29289C6.31658 6.90237 5.68342 6.90237 5.29289 7.29289C4.90237 7.68342 4.90237 8.3166 5.29289 8.7071L7.29289 10.7071C7.68342 11.0976 8.3166 11.0976 8.7071 10.7071L13.5924 5.82182C13.8556 6.49704 14 7.23165 14 8Z" fill="#1E3B5F" />
                                                    </svg>
                                                </div>
                                                <div className="col p-0 ml-2">
                                                    <p className="texto-lato-regular texto-body ">Pós-graduado em perícias Médicas pela Universidade Estácio de Sá</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className=" col d-lg-block d-none">
                                            <p className="texto-lato-regular d-inline texto-body " ><svg width="24" className="mr-2" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C9.8059 2 11.4255 2.79786 12.5255 4.06031L8 8.5858L6.70711 7.29289C6.31658 6.90237 5.68342 6.90237 5.29289 7.29289C4.90237 7.68342 4.90237 8.3166 5.29289 8.7071L7.29289 10.7071C7.68342 11.0976 8.3166 11.0976 8.7071 10.7071L13.5924 5.82182C13.8556 6.49704 14 7.23165 14 8Z" fill="#1E3B5F" />
                                            </svg>Pós-graduado em perícias Médicas pela Universidade Estácio de Sá</p>
                                        </div>
                                    </div>
                                    <div className="row mt-lg-4">
                                        <div className=" col-12 d-block">
                                            <div className="col p-0 ml-1">
                                                <a className="texto-lato-heavy texto-body texto-hover" href="http://lattes.cnpq.br/1665548334620030" target="_blank">Currículo Lattes <svg className="ml-2 mb-1" width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.70711 0.292892C1.31658 -0.0976306 0.68342 -0.0976306 0.29289 0.292892C-0.09763 0.683417 -0.09763 1.31658 0.29289 1.70711L3.58578 5L0.29289 8.29289C-0.09763 8.68342 -0.09763 9.31658 0.29289 9.70711C0.68342 10.0976 1.31658 10.0976 1.70711 9.70711L5.70711 5.70711C6.09763 5.31658 6.09763 4.68342 5.70711 4.29289L1.70711 0.292892Z" fill="#1E3B5F" />
                                                </svg></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-5 mx-auto pl-lg-0">
                                    <div className="row">
                                        <div className="col-lg-10 col-12">
                                            <div className="row">
                                                <div className="col-12 col-lg-6 mt-lg-0 mt-5">
                                                    <h4 className="texto-lato-heavy">Agendamento</h4>
                                                    <p className="texto-lato-regular texto-body d-inline ">Para facilitar, selecione o contato de <strong className="texto-lato-bold texto-primario" style={{ textDecoration: "underline", cursor: "pointer" }} onClick={(e) => goToSection(e)} id="tratamentos">WhatsApp®</strong> referente ao endereço mais próximo de você!  </p>
                                                </div>
                                                <div className="col-12 col-lg-6 mt-lg-0 mt-3">
                                                    <h4 className="texto-lato-heavy">Rede</h4>
                                                    <p className="texto-lato-regular texto-body ">Acesse o instagram do Dr. Luiz Anderson Bevilaqua para atulizações.<strong className=" texto-primario" style={{cursor: "pointer",textDecoration:"underline"}}onClick={() => window.location.href="https://www.instagram.com/bevilaqua.neurocirurgia/?hl=en"}><br></br><svg className="mr-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6 0H14C17.3137 0 20 2.68629 20 6V14C20 17.3137 17.3137 20 14 20H6C2.68629 20 0 17.3137 0 14V6C0 2.68629 2.68629 0 6 0ZM16 3C16.5 3 17 3.5 17 4C17 4.5 16.5 5 16 5C15.5 5 15 4.5 15 4C15 3.5 15.5 3 16 3ZM15 10C15 7.2425 12.7575 5 10 5C7.2425 5 5 7.2425 5 10C5 12.7575 7.2425 15 10 15C12.7575 15 15 12.7575 15 10ZM10 7C8.34315 7 7 8.34315 7 10C7 11.6569 8.34315 13 10 13C11.6569 13 13 11.6569 13 10C13 8.34315 11.6569 7 10 7Z" fill="#1E3B5F" />
                                                    </svg>
                                                        Bevilaqua.neurocirurgia</strong></p>
                                                </div>
                                                <div className="col-12 col-lg-6 mt-lg-4 mt-3">
                                                    <h4 className="texto-lato-heavy">Tratamentos</h4>
                                                    <p className="texto-lato-regular texto-body ">Atuação nas áreas de traumatismo craniano, aneurismas, malfomações, tumores e outros. Clique para conferir <strong className="texto-lato-bold texto-primario" style={{ textDecoration: "underline",cursor:"pointer" }} id="tratamentos" onClick={(e) => goToSection(e)}>lista completa</strong>.</p>
                                                </div>
                                                <div className="col-12 col-lg-6 mt-lg-4 mt-3">
                                                    <h4 className="texto-lato-heavy">Contato</h4>
                                                    <p className="texto-lato-regular texto-body ">Envie uma <strong className="texto-lato-bold texto-primario" id="contato" style={{textDecoration:"underline",cursor:"pointer"}} onClick={(e) => goToSection(e)}>mensagem de email</strong> direto para o Dr. Luiz Anderson Bevilaqua. Acesse o fomulário, preencha seus dados.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}