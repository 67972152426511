import Navbar from "./components/Navbar/Navbar";
import "./assets/css/geral.css";
import Sobre from "./components/Sobre/Sobre"
import Tratamentos from "./components/Tratamentos/Tratamentos"
import Contato from "./components/Contato/Contato";
import Instagram from "./components/Instagram/Instagram";
import Footer from "./components/Footer/Footer";

function App() {
  return (
    <div>
      <Navbar />
      <div className="container-fluid bg-white">
        <Sobre />
      </div>
      <div className="container-fluid" style={{backgroundColor:"#f3f3f3"}}>
        <Tratamentos />
      </div>
      <div className="container-fluid">
        <Contato />
      </div>
      <div className="container-fluid bg-primario-escuro">
        <Instagram />
      </div>
      <div className="container-fluid footer footer-light">
        <Footer />
      </div>
    </div>


  );
}

export default App;
